export default [
  {
    type: 'front',
    content: [
      'Typescript',
      'Javascript',
      'ESM',
      'CSS',
      'Stylus',
      'Less',
      'Sass',
      'UnoCSS',
      'HTML5',
      'Pug',
      'Vue 2',
      'Vue 3',
      'Nuxt',
      'Composition API',
      'Mapbox',
      'Turf.js',
      'Web workers',
      'Service workers',
      'SVG animation',
      'jQuery',
      'Functional programming',
      'Canvas',
      'WebGL',
      'Shaders',
      'SEO',
      'React',
      'React Native',
      'Chart.js',
      'ECharts',
    ],
  },
  {
    type: 'back',
    content: [
      'Node.js',
      'npm',
      'yarn',
      'Fastify',
      'Express.js',
      'Koa.js',
      'Restful API',
      'OpenAPI',
      'MongoDB',
      'SSR',
      'PHP',
      'Go',
      'Wordpress',
      'SQL',
      'Letsencrypt',
      'e-commerce',
    ],
  },
  {
    type: 'tools',
    content: [
      'Vite',
      'Esbuild',
      'Rollup',
      'Babel',
      'Webpack',
      'Sourcemaps',
      'Unit testing',
      'Jest',
      'Vitest',
      'Storybook',
      'CI',
      'Concourse',
      'ytt',
      'Axeptio',
      'Amplitude',
      'Pirsch',
      'Clarity',
      'Stripe',
      'Sharp',
      'Copilot',
      'ChatGPT',
      'Netlify',
    ],
  },
  {
    type: 'devops',
    content: ['Docker', 'Kubernetes', 'Terraform', 'Google Cloud', 'Open Lens'],
  },
  {
    type: 'methodology',
    content: ['BEM', 'Atomic Design', 'Agile', 'Pedagogy'],
  },
  {
    type: 'science',
    content: [
      'C',
      'C++',
      'Fortran',
      'Parallel computing',
      'MPI',
      'OpenMP',
      'Scientific programming',
      'Physics',
      'Data processing',
      'Signal processing',
      'Electronics',
      'Algorithmic',
      'Python',
      'Mathematica',
      'LaTeX',
      'Gnuplot',
    ],
  },
  {
    type: 'core',
    content: [
      'git',
      'vim',
      'ssh',
      'MacOS',
      'Linux',
      'Raspberry',
      'Shell',
      'Bash',
      'Makefile',
      'ffmpeg',
    ],
  },
  {
    type: 'softwares',
    content: [
      'Photoshop',
      'Illustrator',
      'Sketch',
      'Figma',
      'Vscode',
      'Postman',
      'Postico',
      'Android Studio',
      'XCode',
      'Slack',
      'Notion',
    ],
  },
  {
    type: 'art',
    content: [
      'Music composition',
      'Sound design',
      'Digital art',
      'Arduino',
      'MIDI',
    ],
  },
  {
    type: 'languages',
    content: ['Fluent English', 'Medium Spanish'],
  },
] satisfies SkillsGroup[];
