export default [
  {
    title: '4N',
    type: 'Website',
    techInfos: ['PHP, jQuery, skrollr.js'],
    date: '2013',
    imageUrl: '/images/projects/4N-w-logo.jpg',
    smallImageUrl: '/images/projects/small/4N-w-logo.jpg',
    url: 'http://4nparis.com',
    bgColor: '#111111',
    blendMode: 'lighten',
  },
  {
    title: 'JMD',
    type: 'Website',
    techInfos: ['Express.js'],
    date: '2014',
    imageUrl: '/images/projects/JMD-bnw.jpg',
    smallImageUrl: '/images/projects/small/JMD-bnw.jpg',
    url: 'http://jmd.paris',
    bgColor: '#111111',
    blendMode: 'lighten',
  },
  {
    title: 'Fractaleidoscope',
    type: 'Digital art',
    techInfos: ['Python, Arduino'],
    date: '2017',
    imageUrl: '/images/projects/fractaleidoscope.jpg',
    smallImageUrl: '/images/projects/small/fractaleidoscope.jpg',
    url: 'https://youtu.be/ghLG9gDp__A',
    bgColor: '#111111',
    blendMode: 'lighten',
  },
  {
    title: 'Datagif',
    type: 'Integration',
    techInfos: ['HTML/CSS/ES6, PHP'],
    date: '2017',
    imageUrl: '/images/projects/datagif-bnw.jpg',
    smallImageUrl: '/images/projects/small/datagif-bnw.jpg',
    url: 'https://datagif.fr',
    bgColor: '#111111',
    blendMode: 'lighten',
  },
  {
    title: 'inSynch',
    type: 'Game',
    techInfos: ['Music composition, Sound design'],
    date: '2015',
    imageUrl: '/images/projects/inSynch.jpg',
    smallImageUrl: '/images/projects/small/inSynch.jpg',
    url: 'https://store.steampowered.com/app/343410/inSynch',
    bgColor: '#111111',
    blendMode: 'lighten',
  },
  {
    title: 'Lou-Anne Lacaille',
    type: 'Website',
    techInfos: ['2016 - Pug, Webpack, Express.js', '2024 - Pug, Vite, Netlify'],
    imageUrl: '/images/projects/lacaille.jpg',
    smallImageUrl: '/images/projects/small/lacaille.jpg',
    url: 'http://www.lacaille-osteo-beaune.fr',
    bgColor: '#111111',
    blendMode: 'lighten',
  },
  {
    title: 'Bredrin Records',
    type: 'Label',
    techInfos: ['Music composition'],
    date: '2013 - 2024',
    imageUrl: '/images/projects/BR02.jpg',
    smallImageUrl: '/images/projects/small/BR02.jpg',
    url: 'https://soundcloud.com/bredrin-records',
    bgColor: '#111111',
    blendMode: 'lighten',
  },
  {
    title: 'Alors comment ça va ?',
    type: 'Blog',
    techInfos: ['Wordpress'],
    date: '2018',
    imageUrl: '/images/projects/alorscommentcava-bnw.jpg',
    smallImageUrl: '/images/projects/small/alorscommentcava-bnw.jpg',
    url: 'http://alorscommentcava.com',
    bgColor: '#111111',
    blendMode: 'lighten',
  },
  {
    title: 'Assomast',
    type: 'Website',
    techInfos: ['Identity & design, Wordpress'],
    date: '2022',
    imageUrl: '/images/projects/assomast-bnw.jpg',
    smallImageUrl: '/images/projects/small/assomast-bnw.jpg',
    url: 'https://assomast.org/',
    bgColor: '#111111',
    blendMode: 'lighten',
  },
  {
    title: 'PhD Thesis',
    type: 'Print',
    techInfos: ['LaTeX'],
    date: '2013 - 2016',
    imageUrl: '/images/projects/phd-bnw.jpg',
    smallImageUrl: '/images/projects/small/phd-bnw.jpg',
    url: 'https://tel.archives-ouvertes.fr/THESES-UPMC/tel-01432887v1',
    bgColor: '#111111',
    blendMode: 'lighten',
  },
] satisfies Project[];
